import { React } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import consts from "./constants/consts";
import "./App.css";
import OraiFaucet from "./components/OraiFaucet";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={consts.RECAPTCHA_SITEKEY}>
      <Router>
        <Switch>
          <Route path="/" component={OraiFaucet} />
        </Switch>
      </Router>
    </GoogleReCaptchaProvider>
  );
}

export default App;
