import { React, useState, useEffect } from "react";
import cn from "classnames/bind";
import styles from "./OraiFaucetInfo.module.scss";
import NumberFormat from "react-number-format";
import consts from "../../constants/consts";
import axiosInstance from "../../api/axiosIntance";

const cx = cn.bind(styles);

const OraiFaucetInfo = () => {
  const [faucetInfo, setFaucetInfo] = useState({});

  useEffect(() => {
    axiosInstance
      .get(`${consts.API.FAUCET_INFO}`)
      .then((response) => {
        setFaucetInfo(response.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className={cx("faucet-info")}>
      {faucetInfo.account && (
        <div className={cx("faucet-info-description")}>
          <p>
            This faucet drips {faucetInfo.payoutOraiAmount} ORAI every{" "}
            {faucetInfo.payoutFrequencyInSecs} seconds. You can register your account in our queue.
            Serving from account{" "}
            <a
              className={cx("address")}
              target="blank"
              href={`${consts.TESTNET}/account/${faucetInfo.account}`}
            >
              {faucetInfo.account + " "}
            </a>
            (balance{" "}
            <NumberFormat
              value={(faucetInfo.balance / 1000000).toFixed(6)}
              displayType={"text"}
              thousandSeparator={true}
              suffix={" ORAI"}
            />
            ).
          </p>
        </div>
      )}
    </div>
  );
};

OraiFaucetInfo.propTypes = {};
OraiFaucetInfo.defaultProps = {};

export default OraiFaucetInfo;
