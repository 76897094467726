/*
 * enter backend apis
 */

const config = Object.freeze({
  API_DEV: process.env.REACT_APP_API_DEV || "https://api-faucet.orai.io",
  API_PROD: process.env.REACT_APP_API_PROD || "https://api-faucet.orai.io",
  RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD || "",
});

export default config;
