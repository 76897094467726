import { React, useState } from "react";
import cn from "classnames/bind";
import { useForm, FormProvider } from "react-hook-form";
import styles from "./OraiFaucet.module.scss";
import MainLayout from "../MainLayout";
import OraiFaucetInfo from "../OraiFaucetInfo";
import consts from "../../constants/consts";
import axiosInstance from "../../api/axiosIntance";
import timespan from "timespan";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const cx = cn.bind(styles);

const OraiFaucet = () => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [requestRunning, setRequestRunning] = useState(false);
  const [faucetResponse, setFaucetResponse] = useState({});
  const [captchaResponse, setCaptchaResponse] = useState({});

  const { executeRecaptcha } = useGoogleReCaptcha();
  const verifyRecaptcha = async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("faucet");
    const res = await axiosInstance.post(`${consts.API.VERIFY_CAPTCHA}`, {
      "g-recaptcha-response": token,
    });
    return res;
  };

  const onSubmit = async (data) => {
    try {
      const res = await verifyRecaptcha();
      if (res?.data?.responseCode === 1) {
        setCaptchaResponse({
          error: true,
          message: res?.data?.responseDesc,
        });
        return;
      } else if (res?.data?.responseCode === 0) {
        const publicAddress = data.publicAddress.trim();
        if (publicAddress.length === 43 && publicAddress.indexOf("orai") === 0) {
          setRequestRunning(true);

          axiosInstance
            .post(`${consts.API.FAUCET_REQUEST}`, {
              publicAddress: publicAddress,
            })
            .then((response) => {
              setFaucetResponse({
                success: true,
                address: publicAddress,
                message:
                  "You have successfully registered your address to receive ORAI tokens. Please wait roughly 20 seconds to receive them.",
              });
            })
            .catch((error) => {
              if (error?.response?.status === 412) {
                setFaucetResponse({
                  error: true,
                  message:
                    error?.response?.data?.message +
                    " - your greylist period is now extended on address for spamming",
                  time: new timespan.TimeSpan(0, error?.response?.data?.duration),
                });
              } else {
                setFaucetResponse({
                  error: true,
                  status: error?.response?.status,
                  message: error?.response?.data?.message,
                });
              }
            })
            .finally(() => {
              setRequestRunning(false);
            });
        } else {
          setFaucetResponse({
            error: true,
            message: "Invalid address",
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setCaptchaResponse({
          error: true,
          message: "Too many requests.",
        });
      } else {
        console.error(error);
      }
    }
  };

  const clearMessage = () => {
    setFaucetResponse({});
  };

  const clearMessageCaptcha = () => {
    setCaptchaResponse({});
  };

  return (
    <MainLayout>
      <div className={cx("orai-faucet")}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={cx("field")}>
            <div className={cx("followField")}>
              <a href="https://twitter.com/oraichain?ref_src=twsrc%5Etfw" target="_blank" className={cx("followButton")} data-show-count="false">
              <img src='/img/pngwing.com.png' className={cx("followLogo")}></img>
              <span className={cx("follow")}>@oraichain</span> </a>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> 

              <a href="https://t.me/oraichain" target="_blank" className={cx("followButton")} data-show-count="false">
              <img src='/img/telegram-logo-944.png' className={cx("followLogo")}></img>
              <span className={cx("follow")}>@oraichain</span> </a>
            </div>
              <div className={cx("field-title")}>
                Enter your {consts.TEXT_TITLE} account address
              </div>
              <div className={cx("field-input")}>
                <input
                  type="text"
                  className={cx("text-field")}
                  name="publicAddress"
                  autoComplete="off"
                  placeholder={`Enter your ${consts.TEXT_TITLE} account address`}
                  {...register("publicAddress", { required: true })}
                />
              </div>
            </div>

            <button
              type="submit"
              className={cx("button", requestRunning ? "" : "button-active")}
            >
              Send me {consts.TEXT_TEST} ORAI
            </button>
          </form>
        </FormProvider>

        {requestRunning && <div className={cx("running")}></div>}
        <div className={cx("faucet-message")}>
          {captchaResponse.error && (
            <div className={cx("faucet-message-error")} onClick={clearMessageCaptcha}>
              <div className={cx("message-title")}>{captchaResponse.message}</div>
            </div>
          )}

          {faucetResponse.error && (
            <div className={cx("faucet-message-error")} onClick={clearMessage}>
              <div className={cx("message-title")}>{faucetResponse.message}</div>
              {faucetResponse.time && (
                <div className={cx("message-detail")}>
                  You are greylisted for another {faucetResponse.time.hours} hours and{" "}
                  {faucetResponse.time.minutes} minutes
                </div>
              )}
            </div>
          )}

          {faucetResponse.success && (
            <div className={cx("faucet-message-success")} onClick={clearMessage}>
              <div className={cx("message-title")}>
                Registered {faucetResponse.address} to the faucet queue.
              </div>
              <div className={cx("message-detail")}>
                <a className={cx("txhash")} target="blank">
                  {faucetResponse.message}
                </a>
              </div>
            </div>
          )}
        </div>

        {/* <OraiFaucetInfo /> */}
        <div className={cx("quiz")} >
          <details open ><summary class={cx("quizTitle")} >How does this work?</summary>
            <span class={cx("quizAns")}> 
            <OraiFaucetInfo />
            </span>
          </details>

          <details open ><summary class={cx("quizTitle")}>What is a faucet?</summary>
          <span class={cx("quizAns")}>A faucet is a blockchain tool that drips testnet tokens to anyone that requests them for free. You typically use these tokens for creating test transactions on the associated blockchain, such as contract deployments, transferring funds and debugging failed transactions.</span></details>

          <details open><summary class={cx("quizTitle")}>Are there rate limits?</summary><span class={cx("quizAns")}>Yes, we provide one drip every 24 hours.</span></details>

          <details open><summary class={cx("quizTitle")}>What if it doesn't work?</summary><span class={cx("quizAns")}>Give us a shout on <a href="https://twitter.com/oraichain" target="_blank" class={cx("hyperLink")}>Twitter</a> or <a href="https://t.me/oraichain" target="_blank" class={cx("hyperLink")}>Telegram</a>!</span></details>
        </div>
      </div>
    </MainLayout>
  );
};

OraiFaucet.propTypes = {};
OraiFaucet.defaultProps = {};

export default OraiFaucet;
