import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames/bind";
import PropTypes from "prop-types";
import styles from "./MainLayout.module.scss";
import logoIcon from "../../../src/assets/img/logo.svg";
import consts from "../../constants/consts";

const cx = cn.bind(styles);

const MainLayout = ({ children }) => {
  return (
    <div className={cx("main-layout")}>
      <div className={cx("main-layout-header")}>
        <NavLink to="/" className={cx("navbar-brand")}>
          <img className={cx("navbar-brand-icon")} src={logoIcon} alt="" />
          <span className={cx("navbar-brand-title")}>Oraichain {consts.TEXT_TITLE} Faucet</span>
        </NavLink>
      </div>
      <div className={cx("content")}>{children}</div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};
MainLayout.defaultProps = {};

export default MainLayout;
