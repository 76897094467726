// TODO : use this if prod server is ready
import config from "../config";

const api = process.env.NODE_ENV === "production" ? config.API_PROD : config.API_DEV;
const testnet = process.env.REACT_APP_TESTNET;
const recaptcha_sitekey = config.RECAPTCHA_SITEKEY;

const text_title = process.env.REACT_APP_TEXT_TITLE || "";
const text_test = process.env.REACT_APP_TEXT_TEST || "";

export default Object.freeze({
  API_BASE: api,
  TESTNET: testnet,
  API: {
    FAUCET_INFO: "/infos",
    FAUCET_REQUEST: "/users",
    VERIFY_CAPTCHA: "/re-cap",
  },
  RECAPTCHA_SITEKEY: recaptcha_sitekey,
  TEXT_TITLE: text_title,
  TEXT_TEST: text_test,
});
