import axios from "axios";
import consts from "../constants/consts";
const axiosInstance = axios.create({
  baseURL: `${consts.API_BASE}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "access-control-allow-headers": "content-type,x-requested-with",
  },
});

export default axiosInstance;
